var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row text-black"},[_c('div',{staticClass:"col"},[_c('h4',{staticClass:"title-20 mb-1 mt-2 color-blue-600"},[_vm._v(_vm._s(_vm.$t(`home.policies.${_vm.verifyUSTECH() ? 'deliveryT_us' : 'deliveryT'}.DELIVERY`)))]),_c('p',{staticClass:"size-16 mb-0 color-dark-gray-300"},[_vm._v(" "+_vm._s(_vm.$t(`home.policies.${_vm.verifyUSTECH() ? 'deliveryT_us' : 'deliveryT'}.our_delivery_msg`))+" ")]),_c('div',{staticClass:"not-print"},[_c('h4',{staticClass:"title-20 mt-4"},[_vm._v(_vm._s(_vm.$t(`home.policies.${_vm.verifyUSTECH() ? 'deliveryT_us' : 'deliveryT'}.COVERAGE_ZONES`)))]),_c('div',{staticClass:"d-flex flex-column color-dark-gray-300"},[_c('div',{staticClass:"mb-3",staticStyle:{"width":"200px"}},[_c('label',[_vm._v(_vm._s(_vm.$t(`home.policies.${_vm.verifyUSTECH() ? 'deliveryT_us' : 'deliveryT'}.SELECT_STORE`))+": ")]),_c('main-select',{staticClass:"position-relative",staticStyle:{"z-index":"2"},attrs:{"options":_vm.filterOptions,"selectedOption":_vm.selectedOption,"summaryRef":"select-branch"},on:{"clickHandler":(data)=>{_vm.selectedOption = data}}})],1),(_vm.place)?_c('MglMap',{ref:"map",staticStyle:{"height":"400px","width":"100%"},attrs:{"center":[_vm.place.long, _vm.place.lat],"accessToken":'pk.eyJ1IjoicmVwZG9tIiwiYSI6ImNrMTV6cGN4bTEwbTQzaHJzM3FxYXl3aXgifQ.Vue3ItLfwfA6xmA20Xd-tA',"zoom":8,"dragRotate":false,"mapStyle":'mapbox://styles/repdom/ck1to2pew6n411cqrc1fd8cga'},on:{"load":_vm.onMapLoaded}},[(_vm.highlight)?_c('MglMarker',{staticStyle:{"cursor":"pointer"},attrs:{"coordinates":[_vm.marker['long'], _vm.marker['lat']],"draggable":false,"color":"#c40316"}}):_vm._e(),_vm._l((_vm.sources),function(source,index){return _c('mgl-geojson-layer',{key:'maine' + index,ref:"layers",refInFor:true,attrs:{"sourceId":'maine' + index,"clearSource":true,"replaceSource":true,"layerId":'maine' + index,"source":source,"layer":{
            id: 'maine' + index,
            type: 'fill',
            source: 'maine' + index,
            layout: {},
            paint: { 'fill-color': '#c40316', 'fill-opacity': 0.5 },
          }}})}),_c('MglNavigationControl',{attrs:{"position":"top-right"}})],2):_vm._e()],1)]),_c('h4',{staticClass:"title-20 mb-1 mt-4"},[_vm._v(_vm._s(_vm.$t(`home.policies.${_vm.verifyUSTECH() ? 'deliveryT_us' : 'deliveryT'}.how_long_msg`))+"?")]),_c('div',{staticClass:"subtitle-16 color-dark-gray-300"},[_vm._v(" "+_vm._s(_vm.$t(`home.policies.${_vm.verifyUSTECH() ? 'deliveryT_us' : 'deliveryT'}.aproximate_msg`))+" ")]),_c('h4',{staticClass:"title-20 mb-1 mt-3"},[_vm._v(_vm._s(_vm.$t(`home.policies.${_vm.verifyUSTECH() ? 'deliveryT_us' : 'deliveryT'}.shipping_cost_msg`))+"?")]),_c('div',{staticClass:"subtitle-16 color-dark-gray-300"},[_vm._v(" "+_vm._s(_vm.$t(`home.policies.${_vm.verifyUSTECH() ? 'deliveryT_us' : 'deliveryT'}.cost_vary_msg`))+" ")]),_c('h4',{staticClass:"title-20 mb-1 mt-3"},[_vm._v(_vm._s(_vm.$t(`home.policies.${_vm.verifyUSTECH() ? 'deliveryT_us' : 'deliveryT'}.id_receive_msg`))+"?")]),_c('div',{staticClass:"subtitle-16 color-dark-gray-300"},[_vm._v(" "+_vm._s(_vm.$t(`home.policies.${_vm.verifyUSTECH() ? 'deliveryT_us' : 'deliveryT'}.delivery_id_msg`))+" ")]),_c('h4',{staticClass:"title-20 mb-1 mt-3"},[_vm._v(_vm._s(_vm.$t(`home.policies.${_vm.verifyUSTECH() ? 'deliveryT_us' : 'deliveryT'}.not_arrive_msg`))+"?")]),_c('div',{staticClass:"subtitle-16 color-dark-gray-300"},[_vm._v(" "+_vm._s(_vm.$t(`home.policies.${_vm.verifyUSTECH() ? 'deliveryT_us' : 'deliveryT'}.contact_us_msg`))+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }