<template>
  <div class="row text-black">
    <div class="col">
      <h4 class="size-20 weight-600 color-blue-600 mb-1">{{ $t(`home.policies.${verifyUSTECH() ? 'guarantee_us' : 'guarantee'}.warranty`) }}</h4>
      <p class="subtitle-16 color-dark-gray-300">  {{ $t(`home.policies.${verifyUSTECH() ? 'guarantee_us' : 'guarantee'}.warranty_msg`) }} </p>
      <h4 class="title-20 mb-1 mt-2" style="margin-left: 45px">{{ $t(`home.policies.${verifyUSTECH() ? 'guarantee_us' : 'guarantee'}.warranty_consist`) }}</h4>
      <ol class="mb-4" style="margin-left: 40px">
        <li class="color-dark-gray-300"> {{ $t(`home.policies.${verifyUSTECH() ? 'guarantee_us' : 'guarantee'}.repair_msg`) }}</li>
        <li class="color-dark-gray-300"> {{ $t(`home.policies.${verifyUSTECH() ? 'guarantee_us' : 'guarantee'}.deliver_msg`) }}</li>
      </ol>
      <h4 class="title-20 mb-1 mt-2">{{ $t(`home.policies.${verifyUSTECH() ? 'guarantee_us' : 'guarantee'}.what_is_msg`) }}?</h4>
      <p class="size-16 color-dark-gray-300 mb-0">
        {{ $t(`home.policies.${verifyUSTECH() ? 'guarantee_us' : 'guarantee'}.case_msg`) }}
        <span class="color-dark-gray-400">
          {{ $t(`home.policies.${verifyUSTECH() ? 'guarantee_us' : 'guarantee'}.invoice_msg`) }}
        </span>
      </p>
        <!-- <image-item :source="require('../../../assets/images/undraw/undraw_reviewed_docs_neeb.svg')" style="width: 350px; float: right" /> -->
      </div>
      <!-- <image-item :source="require('../../../assets/images/undraw/online_information.svg')" style="width: 350px; float: right" /> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex" 
export default {
  name: "Warranty",
  components: {
    // ImageItem: () => import("../../../components/image/ImageItem"),
  },
  computed:{
    ...mapGetters({
        vidvie: "home/vidvie",
    }),
  },  
  props: {
    highlight: {
      type: Boolean
    }
  }
};
</script>

<style scoped>
</style>
