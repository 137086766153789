<template>
  <div class="row text-black">
    <div class="col">
      <h4 class="title-20 mb-1 mt-2 color-blue-600">{{ $t(`home.policies.${verifyUSTECH() ? 'deliveryT_us' : 'deliveryT'}.DELIVERY`) }}</h4>
      <p class="size-16 mb-0 color-dark-gray-300">
        {{ $t(`home.policies.${verifyUSTECH() ? 'deliveryT_us' : 'deliveryT'}.our_delivery_msg`) }}
      </p>
      <div class="not-print">
      <h4 class="title-20 mt-4">{{ $t(`home.policies.${verifyUSTECH() ? 'deliveryT_us' : 'deliveryT'}.COVERAGE_ZONES`) }}</h4>
      <div class="d-flex flex-column color-dark-gray-300">
        <div class="mb-3" style="width: 200px;">
          <label>{{ $t(`home.policies.${verifyUSTECH() ? 'deliveryT_us' : 'deliveryT'}.SELECT_STORE`) }}: </label>
          <main-select class="position-relative" :options="filterOptions" :selectedOption="selectedOption" @clickHandler="(data)=>{selectedOption = data}" 
            summaryRef="select-branch" style="z-index: 2;" />
        </div>
        <MglMap v-if="place" ref="map"
          style="height: 400px; width: 100%"
          :center="[place.long, place.lat]"
          :accessToken="'pk.eyJ1IjoicmVwZG9tIiwiYSI6ImNrMTV6cGN4bTEwbTQzaHJzM3FxYXl3aXgifQ.Vue3ItLfwfA6xmA20Xd-tA'"
          :zoom="8"
          :dragRotate="false"
          @load="onMapLoaded"
          :mapStyle="'mapbox://styles/repdom/ck1to2pew6n411cqrc1fd8cga'"
        >
        <!-- Seems that without the coordinates being right away when the component is rendered it doesn't show at all (that is why we need the v-if) -->
          <MglMarker
            v-if="highlight"
            style="cursor: pointer"
            :coordinates="[marker['long'], marker['lat']]"
            :draggable="false"
            color="#c40316"
          >
          </MglMarker>
          <mgl-geojson-layer
            :key="'maine' + index"
            v-for="(source, index) in sources"
            :sourceId="'maine' + index"
            :clearSource="true"
            ref="layers"
            :replaceSource="true"
            :layerId="'maine' + index"
            :source="source"
            :layer="{
              id: 'maine' + index,
              type: 'fill',
              source: 'maine' + index,
              layout: {},
              paint: { 'fill-color': '#c40316', 'fill-opacity': 0.5 },
            }"
          >
          </mgl-geojson-layer>
          <MglNavigationControl position="top-right" />
        </MglMap>
      </div>
      </div>
        <h4 class="title-20 mb-1 mt-4">{{ $t(`home.policies.${verifyUSTECH() ? 'deliveryT_us' : 'deliveryT'}.how_long_msg`) }}?</h4>
        <div class="subtitle-16 color-dark-gray-300">
          {{ $t(`home.policies.${verifyUSTECH() ? 'deliveryT_us' : 'deliveryT'}.aproximate_msg`) }}
        </div>
        <h4 class="title-20 mb-1 mt-3">{{ $t(`home.policies.${verifyUSTECH() ? 'deliveryT_us' : 'deliveryT'}.shipping_cost_msg`) }}?</h4>
        <div class="subtitle-16 color-dark-gray-300">
          {{ $t(`home.policies.${verifyUSTECH() ? 'deliveryT_us' : 'deliveryT'}.cost_vary_msg`) }}
        </div>
        <h4 class="title-20 mb-1 mt-3">{{ $t(`home.policies.${verifyUSTECH() ? 'deliveryT_us' : 'deliveryT'}.id_receive_msg`) }}?</h4>
        <div class="subtitle-16 color-dark-gray-300">
          {{ $t(`home.policies.${verifyUSTECH() ? 'deliveryT_us' : 'deliveryT'}.delivery_id_msg`) }}
        </div>
        <h4 class="title-20 mb-1 mt-3">{{ $t(`home.policies.${verifyUSTECH() ? 'deliveryT_us' : 'deliveryT'}.not_arrive_msg`) }}?</h4>
        <div class="subtitle-16 color-dark-gray-300">
          {{ $t(`home.policies.${verifyUSTECH() ? 'deliveryT_us' : 'deliveryT'}.contact_us_msg`) }}
          <!-- Debe comunicarse a través del Email <a style="" href = "mailto: ecommerce@mundomovil.com.do">ecommerce@mundomovil.com.do</a> , colocar en el asunto número de pedido y su nombre, Mundo Móvil investigará la causa y se pondrá en contacto con usted.Los equipos vendidos vía Mundo Móvil tienen un límite de 30 días para la devolución a partir de la fecha de la compra (Para mejor información ver la carta de garantía de Mundo Móvil (si tendrá).-->
        </div>
      <!-- <image-item
        :source="
          require('../../../assets/images/undraw/undraw_empty_cart_co35.svg')
        "
        style="width: 350px; float: right"
      /> -->
    </div>
  </div>
</template>

<script>
import {
  MglMap,
  MglMarker,
  MglNavigationControl,
  MglGeojsonLayer,
} from "v-mapbox";
import Mapbox from "mapbox-gl";
import { mapGetters } from "vuex"

import MainSelect from '../../../../common/components/selects/MainSelect.vue'
// import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as turf from "@turf/turf";
import { http } from "../../../../common/services/web-backend";
export default {
  name: "Delivery",
  components: {
    // ImageItem: () => import("../../../components/image/ImageItem"),
    MglMap,
    MglMarker,
    MglGeojsonLayer,
    MglNavigationControl,
    MainSelect
  },
  data() {
    return {
      place: null,
      control: {},
      limits: [],
      layers: [], 
      selectedOption: { value: 1, title: "Almacen central" },
      marker: {}
    };
  },
  created() {
    this.place = this.branchSelected;
  },  
  props: {
    highlight: {
      type: Boolean
    },
  },
  watch: {
    highlight(data){
      if (data) {
        this.$emit('setHighlight', 'delivery')
      }
    },
    selectedOption({value}){
      this.branches.map((branch)=>{
          if (branch.codplace == value) {
            this.marker['long'] = branch.long;
            this.marker['lat'] = branch.lat;
            return;
          }
      })
    }
  },
  computed: {
    ...mapGetters({
        branchSelected: "branches/branchSelected",
        branches: "branches/branches",
        vidvie: "home/vidvie"
      }),      
      filterOptions(){
        const options = [];
        for(let option of this.branches) { options.push( { value: option.codplace, title: this.$options.filters.textFormat(option.description) }); }
        return options;
      },
    sources() {
      let sources = [];
      for (let layer of this.layers) {
        sources.push({
          type: "geojson",
          data: layer,
        });
      }
      //
      return sources;
    },
  },
  methods: {
    onMapLoaded() {
      this.mapbox = Mapbox;
    },
    async getLimitsClean(data) {
      let limits = [];
      data.forEach((limit) => {
        limits.push(Object.values(limit));
        // console.log(limit)
      });

      return limits;
    },
    async getLimits(id) {
      try {
        if (this.$refs.layers) {
          this.$refs.layers.forEach((e) => {
            e.remove();
          });
          this.layers = [];
        }

        let data = await http.get("stores/" + id + "/limits");
        data = data.data.data;

        // this.layers = [];
        //
        // this.layers.forEach((test, index)=>{
        //   this.$refs.map.map.remove()
        // })

        // this.$refs.map.map.eachLayer(function (layer) {
        //   console.log(layer)
        //   // map.removeLayer(layer);
        // });

        await data.forEach((limit) => {
          let coordinates = [];
          limit.limits.forEach((l, i) => {
            coordinates[i] = [l.longitude, l.latitude];
          });
          let poly = turf.polygon([coordinates]);
          this.layers.push(poly);
        });

        // await limits.data.data[0].limits.forEach((l, i) => {
        //   coordinates[i] = [l.longitude, l.latitude];
        // });
        //
        // let poly = turf.polygon(coordinates);
        // console.log(coordinates)
        // console.log(limits);
        // this.limits =  limits.data.data;
      } catch (e) {
        console.log(e);
      }
    },
  },
  mounted() {
    this.marker['long'] = this.branchSelected.long;
    this.marker['lat'] = this.branchSelected.lat;
  },
};
</script>

<style scoped>
  @media print {
    .not-print {
      display: none;
    }
  }
</style>
