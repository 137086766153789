<template>
  <div class="row text-black">
    <div class="col">
      <h4 class="title-20 mb-1 color-blue-600">{{ $t(`home.policies.${verifyUSTECH() ? 'refund_us' : 'refund'}.refunds`) }}</h4>
      <div class="subtitle-16 color-dark-gray-300">
        {{ $t(`home.policies.${verifyUSTECH() ? 'refund_us' : 'refund'}.merchandise_msg`) }}
      </div>
      <h4 class="title-20 mb-1 mt-4">{{ $t(`home.policies.${verifyUSTECH() ? 'refund_us' : 'refund'}.return_msg`) }}?</h4>
      <div class="subtitle-16 color-dark-gray-300">
        {{ $t(`home.policies.${verifyUSTECH() ? 'refund_us' : 'refund'}.refunded_msg`) }}
      </div>
      <h4 class="title-20 mb-1 mt-4" style="margin-left: 35px">{{ $t(`home.policies.${verifyUSTECH() ? 'refund_us' : 'refund'}.character_msg`) }}</h4>
      <ul class="color-dark-gray-300" style="margin-left: 40px">
        <li>{{ $t(`home.policies.${verifyUSTECH() ? 'refund_us' : 'refund'}.intact_msg`) }}</li>
        <li>{{ $t(`home.policies.${verifyUSTECH() ? 'refund_us' : 'refund'}.guarantee_msg`) }}</li>
        <li>{{ $t(`home.policies.${verifyUSTECH() ? 'refund_us' : 'refund'}.original_msg`) }}</li>
      </ul>
      <h4 class="title-20 mb-1 mt-4" style="margin-left: 35px">{{ $t(`home.policies.${verifyUSTECH() ? 'refund_us' : 'refund'}.responsible_msg`) }}</h4>
      <ul class="color-dark-gray-300 mb-0" style="margin-left: 40px">
        <li>{{ $t(`home.policies.${verifyUSTECH() ? 'refund_us' : 'refund'}.broken_msg`) }}</li>
        <li>{{ $t(`home.policies.${verifyUSTECH() ? 'refund_us' : 'refund'}.warranty_msg`) }}</li>
    <!--<li>Productos enviado por transportes externos a la empresa(SOLO APLICA PARA ENVÍO NACIONALES (CLIENTE DE TIENDA)</li>-->
        <li>{{ $t(`home.policies.${verifyUSTECH() ? 'refund_us' : 'refund'}.purchase_msg`) }}</li>
      </ul>
      <!-- <image-item  :source="require('../../../assets/images/undraw/undraw_upload_87y9.svg')" style="width: 350px; float: right" /> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex" 
export default {
  name: "Devolution",
  components: {
    // ImageItem: () => import("../../../components/image/ImageItem"),
  },
  computed:{
    ...mapGetters({
        vidvie: "home/vidvie",
    }),
  }
};
</script>

<style scoped>
</style>
