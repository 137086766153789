<template>
  <div class="row text-black">
    <div class="col">
        <h4 class="title-20 color-blue-600 mb-1">{{ $t(`home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.title`) }}</h4>
        <div class="subtitle-16 color-dark-gray-300">
          {{ $t(`home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.any_time_msg`) }}
        </div>
        <div class="subtitle-16 color-dark-gray-300">
          {{ $t(`home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.personal_info_msg`) }}
        </div>
      <div class="mt-4">
        <h4 class="title-20 mb-1 mt-2">{{ $t(`home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.policies_msg`) }}</h4>
        <div class="subtitle-16 color-dark-gray-300">
          {{ $t(`home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.website_msg`) }}
        </div>
      </div>
      
      <!-- mm app privacy and policies Start -->
      <h4 class="title-20 color-blue-600 mb-1 mt-3 mb-3">{{ $t(`home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.mm_aplication_only_employees`) }}</h4>
      <h4 class="title-20 mb-1 mt-2">1. {{ $t(`home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.mm_information_we_collect`) }}</h4>
      <div class="ml-40">
        <div class="subtitle-16 color-dark-gray-300 mt-2">
          <span class="fw-bold dark-gray">1.1 {{ $t(`home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.mm_real_time_ubication`) }}:</span>
          {{ $t(`home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.mm_real_time_ubication_explanation`) }}
        </div>

        <div class="subtitle-16 color-dark-gray-300 mt-2">
          <span class=" fw-bold dark-gray">1.2 {{ $t(`home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.mm_background_location`) }}:</span>
          {{ $t(`home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.mm_background_location_explanation`) }}
        </div>

        <div class="subtitle-16 color-dark-gray-300 mt-2">
          <span class="fw-bold dark-gray">1.3 {{ $t(`home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.mm_precise_location`) }}:</span>
          {{ $t(`home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.mm_precise_location_explanation`) }}
        </div>

        <div class="subtitle-16 color-dark-gray-300 mt-2">
          <span class="fw-bold dark-gray">1.4 {{ $t(`home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.mm_contacts`) }}:</span>
          {{ $t(`home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.mm_contacts_explanation`)}}
        </div>

        <!-- <div class="subtitle-16 color-dark-gray-300 mt-2">
          <span class="fw-bold dark-gray">1.5 {{ $t("home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.mm_call_registration")}}:</span>
          {{ $t("home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.mm_call_registration_explanation")}}
        </div> -->

        <div class="subtitle-16 color-dark-gray-300 mt-2">
          <span class="fw-bold dark-gray">1.5 {{ $t(`home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.mm_file_upload`)}}:</span>
          {{ $t(`home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.mm_file_upload_explanation`)}}
        </div>

        <div class="subtitle-16 color-dark-gray-300 mt-2">
          <span class="fw-bold dark-gray">1.6 {{ $t(`home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.mm_making_direct_calls`)}}:</span>
          {{ $t(`home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.mm_making_direct_calls_explanation`)}}
        </div>
      </div>

      <h4 class="title-20 mb-1 mt-3"> 2. {{ $t(`home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.mm_information_usage`)}}:</h4>
      <div class="ml-40">
        <div class="subtitle-16 color-dark-gray-300 mt-2">
          <span class="fw-bold dark-gray">2.1 {{ $t(`home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.mm_application_operation`)}}:</span>
          {{ $t(`home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.mm_application_operation_explanation`)}}
        </div>
      </div>

      <h4 class="title-20 mb-1 mt-3">3.{{ $t(`home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.mm_share_information`)}}:</h4>
      <div class="ml-40">
        <div class="subtitle-16 color-dark-gray-300 mt-2">
          3.1 {{ $t(`home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.mm_share_information_explanation`)}}
        </div>
      </div>

      <h4 class="title-20 mb-1 mt-3">4. {{ $t(`home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.mm_information_protection`)}}: </h4>
      <div class="ml-40">
        <div class="subtitle-16 color-dark-gray-300 mt-2">
          4.1 {{ $t(`home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.mm_information_protection_explanation`)}}
        </div>
      </div>
      
      <h4 class="title-20 mb-1 mt-3">5. {{ $t(`home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.mm_information_access`)}}:</h4>
      <div class="ml-40">
        <div class="subtitle-16 color-dark-gray-300 mt-2">
          5.1 {{ $t(`home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.mm_information_access_explanation`)}}
        </div>
      </div>

      <h4 class="title-20 mb-1 mt-3">6. {{ $t(`home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.mm_question_and_contact`)}}:</h4>
      <div class="ml-40">
        <div class="subtitle-16 color-dark-gray-300 mt2">
          {{ $t(`home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.mm_question_and_contact_explanation_line_1`)}}
        </div>
        
        <div class="subtitle-16 color-dark-gray-300 mt2">
          {{ $t(`home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.mm_quesiton_and_contact_explanation_line_2`)}}
        </div>
      </div>
      <!-- mm app privacy and policies Start -->
      
      <!-- <div class="mt-5">
        <h5 class="title-22 mb-1 mt-2">{{ $t("home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.location_and_tracking") }}</h5>
        <h4 class="title-20 mb-1 mt-2">{{ $t("home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.background_location_and_tracking") }}</h4>
        <div class="subtitle-16 color-dark-gray-300">
          {{ $t("home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.location_and_tracking_msg") }}
        </div>
      </div>
      <div class="mt-4">
        <h4 class="title-20 mb-1 mt-2">{{ $t("home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.how_to_use_info") }}</h4>
        <div class="subtitle-16 color-dark-gray-300">
          {{$t("home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.location_and_tracking_how_to_use_inf")}}
        </div>
      </div>
      <div class="mt-4">
        <h4 class="title-20 mb-1 mt-2">{{ $t("home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.location_party_msg") }}</h4>
        <div class="subtitle-16 color-dark-gray-300">
          {{$t("home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.location_party_inf")}}
        </div>
      </div>
      <div class="mt-4">
        <h4 class="title-20 mb-1 mt-2">{{ $t("home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.prot_inf_msg") }}</h4>
        <div class="subtitle-16 color-dark-gray-300">
          {{$t("home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.prot_inf_inf")}}
        </div>
      </div>
      <div class="mt-4">
        <h4 class="title-20 mb-1 mt-2">{{ $t("home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.access_inf_msg") }}</h4>
        <div class="subtitle-16 color-dark-gray-300">
          {{$t("home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.access_inf_inf")}}
        </div>
      </div>
      <div class="mt-4">
        <h4 class="title-20 mb-1 mt-2">{{ $t("home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.change_polit_msg") }}</h4>
        <div class="subtitle-16 color-dark-gray-300">
          {{$t("home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.change_polit_inf")}}
        </div>
      </div>
      <div class="mt-4">
        <h4 class="title-20 mb-1 mt-2">{{ $t("home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.contact_msg") }}</h4>
        <div class="subtitle-16 color-dark-gray-300">
          {{$t("home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.contact_inf")}}
        </div>
      </div> -->
      <!-- end -->

      <!-- <div class="mt-4">
        <h4 class="title-20 mb-1 mt-2">{{ $t("home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.") }}</h4>
        <div class="subtitle-16 color-dark-gray-300">
          {{$t("home.policies.${verifyUSTECH() ? 'view_privacy_us' : 'view_privacy'}.")}}
        </div>
      </div> -->
      <!-- <image-item :source="require('../../../assets/images/undraw/undraw_Security_on_ff2u.svg')" style="width: 350px; float: right"/> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex" 
export default {
  name: "view-privacy",
  components: {
  },
  computed:{
    ...mapGetters({
        vidvie: "home/vidvie",
    }),
  }
};
</script>

<style scoped>
  .ml-40 {
    margin-left: 40px;
  }

  .dark-gray {
    color: #637684;
  }
</style>