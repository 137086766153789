<template>
  <div class="row text-black">
    <div class="col">
      <h4 class="title-20 color-blue-600 mb-1">{{ $t("home.policies.cancel.cancel_orders") }}</h4>
      <p class="subtitle-16 color-dark-gray-300">
        {{ $t("home.policies.cancel.shipping_msg") }}.
      </p>
      <h4 class="title-20 mb-1 mt-4" style="margin-left: 45px;" >{{ $t("home.policies.cancel.how_cancel") }}</h4>
      <div>
        <ol class="color-dark-gray-300" style="margin-left: 40px">
          <li>{{ $t("home.policies.cancel.go_to") }} 
            <router-link to="/account/orders" class="color-blue-600">{{ $t("home.policies.cancel.my_orders") }}</router-link>
            {{ $t("home.policies.cancel.my_orders_2") }}
            <!-- Ve a <a @click.prevent="goTo('/orders')" style="color: #1b81c5" href="#"  >Mis ordenes</a> y selecciona el pedido que deseas cancelar. -->
            </li>
          <li>{{ $t("home.policies.cancel.check_box") }}.</li>
        </ol>
        <div class="subtitle-16 color-dark-gray-300">
          {{ $t("home.policies.cancel.after_submit") }}
        </div>
        <br>
        <p class="color-dark-gray-400 mb-0">
          {{ $t("home.policies.cancel.order_cancelled") }}
          <!-- También puedes confirmar que la órden se ha cancelado visitando  -->
          <router-link to="/account/orders" class="color-blue-600">{{ $t("home.policies.cancel.my_orders") }}</router-link>.
          <!-- Si ves la orden en la sección órdenes cancelados, se ha cancelado correctamente. -->
          {{ $t("home.policies.cancel.success_cancel") }}
        </p> 
        <!-- <div class="mb-3">-->
        <!--  Si tu pedido lo envía directamente Amazon y no se puede modificar, puedes rechazar el paquete o devolverlo utilizando nuestro Centro de devoluciones.-->
    <!--    </div>-->
        <!-- <div class="mb-3"> -->
          <!-- Si tu pedido lo envía directamente un vendedor y no se puede modificar, contacta al vendedor para obtener instrucciones.-->
          <!-- Para obtener más información acerca de contactar al vendedor, ve a Contactar a un vendedor externo.-->
        <!-- </div>-->

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Cancel",
}
</script>

<style scoped>

</style>