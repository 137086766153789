<template>
  <div class="row text-black">
  <div class="col">
      <h4 class="title-20 mb-1 mt-2 color-blue-600">{{ $t("home.policies.pr.pick_up") }}</h4>
      <p class="size-16 color-dark-gray-300">{{ $t("home.policies.pr.online_store_msg") }}</p>
      <p class="subtitle-16 color-dark-gray-300 mb-3 pb-3">{{ $t("home.policies.pr.pick_up_msg") }}</p>
      <h4 class="title-20 mb-1 mt-2">{{ $t("home.policies.pr.who_pick_up") }}?</h4>
      <p class="subtitle-16 color-dark-gray-300">{{ $t("home.policies.pr.you_can_pick_msg") }}</p>
      <h4 class="title-20 mb-1 mt-4" style="margin-left: 45px">{{ $t("home.policies.pr.important_data") }}</h4>
      <ul class="mb-0" style="margin-left: 40px;">
        <li class="color-dark-gray-300">{{ $t("home.policies.pr.no_charge_msg") }}</li>
        <li class="color-dark-gray-300">{{ $t("home.policies.pr.larger_msg") }}</li>
        <li class="color-dark-gray-300">{{ $t("home.policies.pr.extra_time_msg") }}</li>
      </ul>
      <!-- <image-item :source=" require('../../../assets/images/undraw/undraw_site_content_ihgn.svg')" style="width: 350px; float: right" /> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Pr",
  components: {
    // ImageItem: () => import("../../../components/image/ImageItem"),
  },
  props: {
    highlight: {
      type: Boolean
    }
  }
};
</script>

<style scoped>
ul li {
  list-style: outside;
}
li {
  padding: 5px;
}
</style>
