
<template>
    <div class="mt-5">
        <div class="row mb-5">
            <div class="d-flex col-md-12 px-2">
                <div class="d-flex align-content-center cursor-pointer hover-back-arrow" @click="goBack">
                    <icon-arrow class="rotate-arrow me-3" :style="{fill: arrowColor}" :size="25"/>
                </div>
                <div>
                    <h1 class="d-block size-23 weight-600 color-blue-700 mb-0 cursor-pointer" @mouseover="arrowColor = 'var(--blue-600)'" @mouseleave="arrowColor = 'var(--gray-1001)'" @click="goBack">{{$t("layout.footer.policies")}}</h1>
                    <div class="d-flex flex-column flex-sm-row">
                        <p class="d-flex color-dark-gray-200 mb-0 pe-3 cursor-pointer" @mouseover="arrowColor = 'var(--blue-600)'" @mouseleave="arrowColor = 'var(--gray-1001)'" @click="goBack">{{$t("home.policies.download")}} {{ $d(new Date(), 'short') | textFormat }}</p>
                        <div class="d-flex">
                            <span style="top: -8px" class="download-pdf cursor-pointer position-relative" @mouseover="btnHovered = true" @mouseleave="btnHovered = false">
                                <transition name="icon">
                                    <icon-download v-show="btnHovered" :size="17" class="position-absolute py-2" style="top: 4px;"/>
                                </transition>
                                <span v-print="'policies'" style="width: 120px" :class="[btnHovered ? 'move-right':'move-left']" class="position-absolute py-2 color-blue-600 cursor-pointer">
                                    {{$t("home.policies.download")}} PDF
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-percentages d-none position-fixed p-3 bg-white"> <!-- used for testing ***DO NOT REMOVE*** -->
                <span class="text-center">{{focused}}</span>
                <span v-for="(value, name, index) of sectionVisible" :key="index" class="d-block">
                    <b class="weight-600">{{name}}:</b> <b class="weight-400" :style="{color: value === 100 ? 'var(--main-red)':'var(--dark-gray-200)'}" >{{value}}</b>
                </span>
            </div>
        </div>
        <div class="row mt-5 justify-content-xl-between justify-content-xxl-start">
            <div class="sticky-column position-sticky col-lg-4 col-xl-3 me-xl-5">
                <card class="d-flex card-responsive p-1 p-lg-4 ms-xl-5" :class="{'card-shadow': detached}">
                    <ul ref="card" class="d-flex justify-content-center flex-column flex-wrap px-2 pb-0 m-0 ">
                        <li :key="key" v-for="({title, key, icon}) in tabs" class="d-flex nav-item" :class="{focused: focused === key}" @click="scrollTo(key)">
                            <component :is="icon" class="me-3" />
                            <span class="nav-link px-0 color-dark-gray-200">
                                {{ $t(title) | textFormat }}
                            </span>
                        </li>
                    </ul>
                </card>               
            </div>
            <div class="col-lg-8 text-justify mt-5 mt-lg-0">
                <h1 class="size-24 weight-600 color-dark-main-navy" v-if="$store.getters['auth/userData']">
                    {{ $t("home.policies.help.HI") }},
                    {{ $store.getters["auth/userData"].entity.first_name }}. 
                    {{$t("home.policies.help.HOW_CAN_WE_HELP_YOU")}}?
                </h1>
                <div id="policies">
                    <scroll-view>
                        <template slot-scope="policies" :offset="100">
                            <pr class="mb-4 pb-2" ref="pr" id="pr" name="pr" key="pr" :highlight="policies.pr" />
                            <delivery class="mb-4 pb-2" ref="delivery" id="delivery" name="delivery" key="delivery" :highlight="policies.delivery" />
                            <warranty class="mb-4 pb-2" ref="warranty" id="warranty" name="warranty" key="warranty" :highlight="policies.warranty" />
                            <devolution class="mb-4 pb-2" ref="devolution" id="devolution" name="devolution" key="devolution" :highlight="policies.devolution" />
                            <cancel class="mb-4 pb-2" ref="cancel" id="cancel"  name="cancel" key="cancel" :highlight="policies.cancel" />
                            <view-privacy class="mb-4 pb-2" ref="privacy" id="privacy" name="privacy" key="privacy" :highlight="policies.privacy" />
                        </template>
                    </scroll-view>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex"
import { $scrollview } from 'vue-scrollview'

import Card from '../../../common/components/cards/Card.vue';
import Warranty from "./../components/policies/Warranty";
import Devolution from "./../components/policies/Devolution";
import Cancel from "./../components/policies/Cancel";
import Delivery from "./../components/policies/Delivery";
import ViewPrivacy from "./../components/policies/ViewPrivacy";
import Pr from "./../components/policies/Pr";

export default {
name: "Help",
components: {
    Warranty,
    Devolution,
    Cancel,
    Delivery,
    ViewPrivacy,
    Pr,
    Card,
    IconArrow: () => import("@/common/svg/iconArrow.vue"),
    //tab icons
    IconCancelation: () => import("@/modules/home/assets/svg/policies/iconCancelations.vue"),
    iconDevolutions: () => import("@/modules/home/assets/svg/policies/iconDevolutions.vue"),
    iconWarranty: () => import("@/modules/home/assets/svg/policies/iconWarranty.vue"),
    iconPickups: () => import("@/modules/home/assets/svg/policies/iconPickups.vue"),
    iconPolicyDelivery: () => import("@/modules/home/assets/svg/policies/iconPolicyDelivery.vue"),
    iconSecurity: () => import("@/modules/home/assets/svg/policies/iconSecurity.vue"),
    iconDownload: () => import("@/common/svg/iconDownload.vue"),
},
data() {
    return {
        tabs: [
            { title: "home.policies.pickup", key: "pr", icon: "iconPickups" },
            { title: "home.policies.delivery", key: "delivery", icon: "iconPolicyDelivery" },
            { title: "home.policies.warranty", key: "warranty", icon: "iconWarranty" },
            { title: "home.policies.returns", key: "devolution", icon: "iconDevolutions" },
            { title: "home.policies.cancellations", key: "cancel", icon: "iconCancelation" },
            { title: "home.policies.security_&_privacy", key: "privacy",  icon: "iconSecurity", },
        ],
        sectionVisible: {
            pr: null,
            delivery: null,
            warranty: null,
            devolution: null,
            cancel: null,
            privacy: null,
        },
        detached: null,
        sectionCounter: null,
        focused: 'pr',
        btnHovered: null,
        arrowColor: 'var(--gray-1001)'
    };
},
computed: {
    ...mapGetters({
        user: "auth/userData",
    }),      
    thresholdList(){ //intersection observer utility
        let thresholds = [];
        const numSteps = 20;

        for (let i = 1.0; i <= numSteps; i++) {
        let ratio = i / numSteps;
        thresholds.push(ratio);
        }
        thresholds.push(0);
        return thresholds;
    },
},
methods: {
    scrollTo(key){
        if (key == "pr") {
            window.scrollTo(0,0)
        }else {
            this.viewPort('lg') ? $scrollview.scrollToComponent(key, 120): $scrollview.scrollToComponent(key, 180); //some threshold to limit the scroll capacity
        }
        this.focused = key;
    },
    handleScroll() {
        if (window.scrollY > 200) {
            this.detached = true
        } else {
            this.detached = false
        }
    },
    goBack(){
        this.$router.go(-1)
    }
},
watch: {
    sectionVisible: {
    //on each change this will evaluate every value to change the activeScroll property
        handler: function (sections) {
            clearTimeout(this.sectionCounter)
            let list = this.$refs.card;
            this.sectionCounter = setTimeout(() => {
                let maxVisibility;
                let maxPercentage;
                Object.keys(sections).map((key) => {
                    if(!maxVisibility || sections[key] > maxPercentage || sections[key] == 100){
                        maxPercentage = sections[key];
                        maxVisibility = key;
                    }
                });
                this.focused = maxVisibility
                //exceptions (mobile)
                if (!this.viewPort('lg')) { //scrolling the card horizontally when we are on said sections
                    if (this.focused == 'warranty' || this.focused == 'devolution') {
                        list.scroll({left: 135, behavior: 'smooth'});
                    }else if (this.focused == 'cancel' || this.focused == 'privacy') {
                        list.scroll({left: 300, behavior: 'smooth'});
                    }else {
                        list.scroll({left: 0, behavior: 'smooth'});
                    }
                }
                // exceptions (desktop)
                if (sections.cancel == 100) { // we need to check when 2 sections have a percentage of visibility of 100% to see what section we will focus
                    this.focused = 'cancel';
                }
                if (sections.cancel == 100 && sections.devolution == 100) {
                    this.focused = 'devolution';
                }
                if (sections.devolution == 100 && sections.warranty == 100) {
                    this.focused = 'warranty';
                }
            }, 150)
        },
    deep: true
    }
},
created(){
    window.addEventListener('scroll', this.handleScroll); // applying the shadow to the navigator once we scroll enough
},
mounted(){
    // Intersection Observer (other options brought several issues on mobile versions or smaller page heights).
    // This also could be improved by integrating promises.
    let observableSections = [this.$refs.pr, this.$refs.delivery, this.$refs.warranty, this.$refs.devolution, this.$refs.cancel, this.$refs.privacy]
    observableSections.map( ({$el}) => {
        let observer;
        let options = {
            rootMargin: "0px",
            threshold: this.thresholdList
        };
        let handleIntersect = (entries) => {
            entries.forEach((entry) => {
                this.sectionVisible[$el.id] = Math.floor(entry.intersectionRatio * 100)
            });
        }
        observer = new IntersectionObserver(handleIntersect, options);
        observer.observe($el)
    })
},
destroyed(){
    clearTimeout(this.sectionCounter)
    window.removeEventListener('scroll', this.handleScroll);
}
};
</script>

<style scoped>
/* title */
.hover-back-arrow:hover > svg {
    fill: var(--blue-600);
}

/* printing options */
.rotate-arrow {
    transition: var(--transition-1);
    transform: rotate(180deg) translateX(4px) !important;
}

/* download button */
    .icon-enter-active, .icon-leave-active {
        transition: all .15s ease-out;
    }
    .icon-enter-active {
        transition-delay: .07s;
    }
    .icon-enter, .icon-leave-to {
        opacity: 0;
    }
    .icon-leave, .icon-enter-to {
        opacity: 1;
    }
    .download-pdf > span {
        transition: all 0.15s var(--alt-ease-in-out);
    }
    .download-pdf:hover > span {
        color: var(--main-red) !important;
        text-decoration: underline;
    }
    .download-pdf svg::v-deep > g > path {
        fill: var(--blue-600);
        stroke: var(--blue-600);
        transition: var(--transition-1);
    }
    .download-pdf:hover > svg::v-deep > g > path {
        fill: var(--main-red);
        stroke: var(--main-red);
    }
/* moving right and left */
.move-right {
    left: 27px;
}
.move-left {
    left: 2px;
}
/* sticky-scroll navigation */
    .nav-item {
        transition: var(--transition-1);
    }
    .nav-item > svg::v-deep > path {
        transition: var(--transition-1);
    }
    .nav-item:hover svg::v-deep > path {
        fill: var(--gray-100) !important;
    }
    .nav-item:hover svg::v-deep > path {
        fill: var(--gray-100) !important;
    }
    .nav-link {
        transition: var(--transition-1);
    }
    .nav-item:hover .nav-link {
        color: var(--gray-100) !important;
    }

    /* focused */
    .focused > span {
        color: var(--blue-600) !important;
    }
    .focused > svg::v-deep > path {
        fill: var(--blue-600) !important;
    }

    .nav-item.focused:hover > span {
        color: var(--blue-600) !important;
    }
    .focused.nav-item:hover svg::v-deep > path {
        fill: var(--blue-600) !important;
    }

.section-percentages {
    bottom: 0; 
    left: 0; 
    z-index: 10;

    box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%);
    border-radius: 5px;
    max-width: 160px;
}

.card-responsive {
    height: fit-content;
}

@media (max-width : 991px) {
    .sticky-column {
        height: fit-content;
        /* padding-bottom: 20px; */
        top: 64px;
        z-index: 8;
    }
    .card-responsive {
        width: 100%;
        box-shadow: none;

        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        border-radius: 7px;
    }
    .card-shadow {
        box-shadow: 2px 3px 9px 0px #909da41a;
    }
    ul {
        height: 95px;
        max-width: 100%;
        overflow-x: scroll;
        list-style: none;
    }
    ul > li:nth-child(n+3) {
        padding-left: 40px;
    }
}
@media (max-width: 768px) {
    ul > li:nth-child(n+3) {
        padding-left: 26px;
    }
}
@media (max-width: 576px) {
    ul {
        height: 85px;
    }
    ul > li:nth-child(n+3) {
        padding-left: 40px;
    }
}
@media (min-width : 992px) {
    .card-responsive {
        width: 280px;
        
        border-top-left-radius: 15px !important;
        border-top-right-radius: 15px !important;
        border-radius: 15px;
    }
    .sticky-column {
        height: 350px; 
        top: 108px;
    }
    ul {
        height: fit-content;
    }
}
</style>
